/**
 * Created by vaibhav on 31/3/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import IRPageWrapper from "../../layouts/IRPageWrapper"
import IRNav from "../../components/IRNav"
import IRBG from "../../components/IRBG"
import HomeLogo from "../../components/HomeLogo"

export default class AnalystPage extends Component {
  render() {
    const { data } = this.props
    const { edges: analysts } = data.allMarkdownRemark

    return (
      <IRPageWrapper>
        <IRBG />
        <Helmet>
          <title>Analysts - EA Technique</title>
        </Helmet>
        <section className="hero is-small">
          <div className="columns">
            <div className="column is-offset-1">
              <div className="section">
                <HomeLogo />
                <h1 className="title" id="title-line">
                  Analysts
                </h1>
                <IRNav />
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="column is-offset-2 ">
            {analysts
              .filter(
                (analyst) =>
                  analyst.node.frontmatter.templateKey === "analyst-page"
              )
              .map(({ node: analyst }) => (
                <div
                  className="news_item is-size-5"
                  key={analyst.id}
                  style={{ transform: `none` }}
                >
                  <p className="news_item-date">{analyst.frontmatter.date}</p>
                  <p>{analyst.frontmatter.title}</p>
                  <a href={analyst.frontmatter.manual_pdf} download>
                    download
                  </a>
                </div>
              ))}
            {/* <table>
                <tr>
                  <th>Title</th>
                  <th>Date</th>
                  <th>Download</th>
                </tr>
                <tr>
                {analysts.map((analystGroup, index) => (
                <div>
                  <td key={index}>{analyst.frontmatter.title}</td>
   
                </div>
                ))}

               </tr>
              </table> */}
          </div>
        </section>
      </IRPageWrapper>
    )
  }
}

AnalystPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const analystPageQuery = graphql`
  query AnalystPage {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "analyst-page" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            year
          }
        }
      }
    }
  }
`
